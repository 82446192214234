/**
 *
 * Base Input
 *
 *
 */

import styled from 'styled-components';

export const StyledInput = styled.input`
  display: inline-block;
  background-color: #fff;
  width: 100%;
  font-size: 1rem;
  height: 3rem;
  padding: 5px;
  padding-left: 10px;
  transition: border-color 0.3s;
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  &::placeholder {
    vertical-align: middle;
    font-size: 1rem;
  }
  &:hover {
    border-color: ${({ theme }) => theme.colors.darkGrey};
  }
`;

export default StyledInput;
