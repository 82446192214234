/**
 *
 * SearchInput
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Button from 'components/Elements/Button';
import StyledInput from './Base';

const SearchInput = ({
  type = 'text',
  onChange,
  value,
  searchText = '',
  onClick,
  ...props
}) => {
  return (
    <ContentWrapper>
      <SearchInputWrapper>
        <StyledInput
          className="search-input"
          type={type}
          onChange={onChange}
          value={value}
          {...props}
        />
      </SearchInputWrapper>
      <Button primary onClick={onClick}>
        {searchText}
      </Button>
    </ContentWrapper>
  );
};

SearchInput.propTypes = {
  type: PropTypes.string,
  searchText: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
};

export default SearchInput;

const SearchInputWrapper = styled.div`
  width: 100%;
  margin-right: 20px;
  border-radius: 3px;
  border: 1px solid ${({ theme }) => theme.colors.midGrey};
  transition: border-color 0.3s;
  &:active,
  &:hover {
    border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  }
  input.search-input {
    flex: 4;
    min-height: 100%;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border: none !important;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  width: 100%;
`;
