/**
 *
 * Animated Input
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import StyledInput from './Base';

const AnimatedInput = ({
  type = 'text',
  onChange,
  value,
  placeholder,
  labelPosition = 'fieldset', // fieldset | above
  ...props
}) => {
  return (
    <Container>
      <StyledInputAnimated
        hasText={value && value !== ''}
        type={type}
        onChange={onChange}
        value={value}
        labelPosition={labelPosition}
        {...props}
      />
      <Label>{placeholder}</Label>
    </Container>
  );
};

AnimatedInput.propTypes = {
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  labelPosition: PropTypes.string,
  onChange: PropTypes.func,
};

export default AnimatedInput;

const Container = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background-color: transparent;
  width: 100%;
  font-size: 1rem;
  line-height: 2rem;
`;

const Label = styled.label`
  position: absolute;
  left: 5px;
  top: 50%;
  color: #c4c0bb;
  display: inline-block;
  padding-left: 5px;
  pointer-events: none;
  transform: translate3d(0, -50%, 0) scale(1);
  -webkit-transform-origin-x: 5px;
  transition: transform 0.3s, padding-left 0.3s;
`;

const toggledLabelFieldsetStyles = css`
  background-color: #fff;
  border-radius: 10px;
  padding-left: 15px;
  padding: 0px 5px;
  border-radius: 10px;
  transform: translate3d(0px, -120%, 0) scale(0.75);
`;

const toggledLabelAboveStyles = css`
  transform: translate3d(0px, -52px, 0) scale(0.75);
`;

const StyledInputAnimated = styled(StyledInput)`
  outline: none;
  &:focus {
    + label {
      ${({ labelPosition }) =>
        labelPosition === 'fieldset'
          ? toggledLabelFieldsetStyles
          : toggledLabelAboveStyles}
    }
  }
  ${({ hasText }) =>
    hasText &&
    css`
      + label {
        ${({ labelPosition }) =>
          labelPosition === 'fieldset'
            ? toggledLabelFieldsetStyles
            : toggledLabelAboveStyles}
      }
    `}
`;
