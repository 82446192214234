/**
 *
 * Formik - formik styled componwents
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Field } from 'formik';

import Input, { AnimatedTitleInput } from 'components/Elements/Input';

export const FormWrapper = ({ children }) => <Container>{children}</Container>;

FormWrapper.propTypes = {
  children: PropTypes.any,
};

const Container = styled.div`
  ${({ theme }) => theme.styles.grow}
  position: relative;
  width: 100%;
  height: 100%;
  padding: 15px;
  border-radius: 3px;
`;

export const StyledField = ({
  type,
  name,
  placeholder,
  touched,
  errors,
  wrapperStyle,
  useAnimated = false, // Flag for setting animated title or not (title slides up above input)
  ...props
}) => (
  <FieldWrapper style={wrapperStyle}>
    <Field
      type={type}
      name={name}
      placeholder={placeholder}
      component={useAnimated ? AnimatedTitleInput : Input}
      {...props}
    />
    <StyledError error={name in touched && errors[name]} />
  </FieldWrapper>
);

StyledField.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  touched: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  wrapperStyle: PropTypes.any,
  useAnimated: PropTypes.bool,
};

export const StyledError = ({ children, style, className, error }) => (
  <MyError style={style} className={className} error={error}>
    {children}
    {error}
  </MyError>
);

const MyError = styled.div`
  color: ${({ theme }) => theme.colors.Red};
  font-family: 'Montserrat', sans-serif;
  overflow: hidden;
  font-size: 1rem;
  transition: height 0.5s, opacity 0.3s ease 0.2s;
  ${({ error }) =>
    error
      ? css`
          height: 30px;
          opacity: 1;
          margin-left: 0.4rem;
          margin-top: 0.6rem;
        `
      : css`
          height: 0px;
          opacity: 0;
        `}
`;

StyledError.propTypes = {
  children: PropTypes.any,
  style: PropTypes.object,
  className: PropTypes.string,
  error: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.object,
  ]),
};

export const FieldWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
`;

export const Extras = styled.div`
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 0.9rem;
  padding: 20px 5px;
  a {
    color: ${({ theme }) => theme.colors.darkGrey};
    /* font-weight: bolder; */
    text-decoration: underline;
    margin-left: 10px;
    &:hover {
      color: ${({ theme }) => theme.colors.button.hover};
    }
  }
`;

export const ExtraAlt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${({ theme }) => theme.colors.darkGrey};
  font-size: 1rem;
  padding: 20px 5px;
`;

export const FormControls = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.media.min.mobile`
    flex-direction: row;
  `}

  button {
    position: relative;
    flex: 1;
    padding: 10px 20px;
    background-color: transparent;
    transition: background-color 0.3s;
    border-radius: 3px;
    border: 1px solid #000;
    cursor: pointer;
    &:hover {
      background-color: rgba(255, 255, 255, 0.2);
    }
  }
`;
