/**
 *
 * Input
 *
 *
 */

import React from 'react';
import PropTypes from 'prop-types';

import _AnimatedTitleInput from './AnimatedInput';
import _SearchInput from './SearchInput';
import StyledInput from './Base';

/**
 * Standard Input
 *
 * @param {*} { type = 'text', onChange, value, ...props }
 */
const Input = ({ type = 'text', onChange, value, ...props }) => (
  <StyledInput type={type} onChange={onChange} value={value} {...props} />
);

Input.propTypes = {
  type: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
};

export default Input;

// Animated Title Input
export const AnimatedTitleInput = _AnimatedTitleInput;
export const SearchInput = _SearchInput;
